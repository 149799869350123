<template>
    <div class="unit-info-prometheus show-info unit-info-prometheus-reefer"
         :class="[!isSectionInfoOpen && 'show-info--close']"
    >
        <button class="button button-close"
                :class="[!isSectionInfoOpen && 'button-close-info']"
                @click="toggleList"
        >
            <SvgIcon name="buble-arrow" class="icon" v-if="!isSectionInfoOpen"/>
        </button>

        <div class="unit-info-prometheus-reefer__img-container">
            <img :src="require('@/assets/img/reefer-icon/thermo-king.png')" alt="Thermo King">
        </div>

        <div class="unit-info-prometheus-reefer__body">
            <div class="unit-info-prometheus__title">{{unit.name}}</div>

            <div class="unit-info-prometheus-reefer__line"></div>

            <UnitInfo__sensors
                class=""
                :sensors="['type', 'imei', 'last_message', 'address']"
                :unitId="unitId"
            />
            <div class="unit-info-prometheus-reefer__line"></div>

            <button class="button button_view_base button_theme_green mb__20"
                    style="width: 100%"
                    :disabled="isBlockCommands"
                    @click="setCommandAction('onRemoteStart')"
                    v-if="!isPowerOn"
            >
                {{ $t('Reefer.button.Remote Start') }}
            </button>
            <button class="button button_view_base button_theme_red mb__20"
                    style="width: 100%"
                    :disabled="isBlockCommands"
                    @click="setCommandAction('onRemoteStop')"
                    v-else
            >
                {{ $t('Reefer.button.Remote Stop') }}
            </button>
            <button class="button button_view_base button_theme_primary"
                    style="width: 100%"
                    @click="setCommandAction('onSetPinCode')"
            >
                {{ pinCodeAction }}
            </button>

            <div id="sectionInfoByPage" class="page-block" v-show="false"></div>
        </div>

        <custom-modal v-if="modalWindows?.onRemoteStart" v-bind="modalWindows?.onRemoteStart?.props">
            <p class="size-14 text-align-center">{{ $t('Reefer.command_info_send') }}</p>
            <br>
            <p class="size-14 text-align-center">{{ $t('popup.Are you sure') }}</p>
        </custom-modal>
        <custom-modal v-if="modalWindows?.onRemoteStop" v-bind="modalWindows?.onRemoteStop?.props">
            <p class="size-14 text-align-center">{{ $t('Reefer.command_info_send') }}</p>
            <br>
            <p class="size-14 text-align-center">{{ $t('popup.Are you sure') }}</p>
        </custom-modal>

        <!--   custom-modal     -->
        <CommandsConfirmationPINCode
            :action="commandAction"
            @clearAction="clearCommandAction"
            :onlyEdit="onlyPinCodeEdit"
            v-if="commandAction"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import routeMixin from "@/mixins/routeMixin";
import UnitInfo__sensors from "@/components/units/info/UnitInfo__sensors.vue";
import sendCommandMixin from "@/mixins/sendCommandMixin";
import CommandsConfirmationPINCode from "@/components/commands/popup/CommandsConfirmationPINCode.vue";

export default {
    setup() {
        return {
            defaultAccordionInfo: ['sensors'],
        }
    },
    name: "UnitInfo_reefer.prometheus",
    inject: ['fnAddPageClass','fnRemovePageClass'],
    mixins: [routeMixin, sendCommandMixin],
    props: {
        unitId: [String, Number],
    },
    components: {
        CommandsConfirmationPINCode,
        UnitInfo__sensors,
    },
    data() {
        return {
            isSectionInfoOpen: true,
            modalWindows: {},
            disabled: false,
            commandAction: null,
            onlyPinCodeEdit: false,
        }
    },
    computed: {
        ...mapGetters([
            "units",
            "unitsLmsgs",
            "getCommands",
            "isCommandsFullLoad",
            "unitsLmsgsFullLoadByIds",
            "getAppUser",
        ]),
        hideInfoUnit() {
            return this.routeMeta?.hideInfoUnit
        },
        unit() {
            return this.units?.find(u => u.id == this.unitId) || {};
        },
        hasVideo() {
            return !!this.unit?.video
        },
        unitLmsg() {
            return this.unitsLmsgs.find(l => l.object_id == this.unitId);
        },
        isPowerOn() {
            return this.unitLmsg?.sensors?.power?.value
        },
        pinCodeAction() {
            let isCmdPinCode = !!this.getAppUser?.cmd_pin_code;
            return (isCmdPinCode ? this.$t('Update') : this.$t('Set')) + ' ' + this.$t('User.Command Pin Code')
        },
        isBlockCommands() {
            return !this.isCommandsFullLoad
                || !this.unitsLmsgsFullLoadByIds[this.unitId]
                || this.getCommands?.filter(c => c.unit_id == this.unitId && (!c?.time_answer || c?.time_answer >= (Date.now()/1000))).map(c => c['sub-type'])?.length
        },
    },
    watch: {
        routeName: {
            handler: function () {
                this.isSectionInfoOpen = !this.hideInfoUnit
            }
        },
    },
    methods: {
        ...mapActions([
            "saveAppUser",
        ]),
        modalClose() {
            this.modalWindows = {}
        },
        clearCommandAction() {
            this.commandAction = null;
            this.onlyPinCodeEdit = false;
        },
        setCommandAction(action) {
            this.onlyPinCodeEdit = action === 'onSetPinCode';
            this.commandAction = () => this[action]()
        },
        onRemoteStart(){
            this.modalWindows = {
                'onRemoteStart':{
                    props:{
                        title: this.$t('Reefer.button.Remote Start'),
                        modalClass: 'modal-600',
                        btnOkClass: 'btn-ok button_theme_neutral',
                        buttons:[
                            {id: 'ok', fn: () => this.sendCmd('RemoteOn')},
                            {id: 'cancel', fn: () => this.modalClose()},
                        ]
                    }
                }
            }
        },
        onRemoteStop(){
            this.modalWindows = {
                'onRemoteStop':{
                    props:{
                        title: this.$t('Reefer.button.Remote Stop'),
                        modalClass: 'modal-600',
                        btnOkClass: 'btn-ok button_theme_neutral',
                        buttons:[
                            {id: 'ok', fn: () => this.sendCmd('RemoteOff')},
                            {id: 'cancel', fn: () => this.modalClose()},
                        ]
                    }
                }
            }
        },
        onSetPinCode() {
            //action in component CommandsConfirmationPINCode
        },
        toggleList() {
            this.isSectionInfoOpen = !this.isSectionInfoOpen
            if(this.isSectionInfoOpen) {
                this.fnAddPageClass('open-info')
            } else {
                this.fnRemovePageClass('open-info')
            }
            // this.setRoute.unitId(this.$route, this.$router, this.unitId)
        },
    },
    created() {
    },
    mounted() {
        this.isSectionInfoOpen = !this.hideInfoUnit
    }
}
</script>

<style lang="scss" scoped>
.unit-info-prometheus {
    padding: 13px;
    background: var(--unit-info-bg);
    border: var(--unit-info-border);
    box-shadow: var(--unit-info-box-shadow);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    position: relative;
    @include scroll(var(--unit-info-bg), var(--section-list-item-active-bg), 6px);

    &__title {
        @include font(18px, normal, 700, none, normal, var(--Primary-Text-in-Light-Mode));
        @include adaptive-font(18, 14);
    }

    &__icon {
        color: var(--color-default);
    }

    &__box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 5px;

        padding: 10px 0;

        &:first-child {
            border-bottom: solid 1px var(--border-color);
        }

        .unit-indicators__item:last-child {
            justify-content: flex-end;
        }
    }

    &__box-item {
        //max-width: 50%;
        flex-wrap: wrap;

        &.unit-indicators {
            min-height: 55px;
            align-items: flex-start;
        }
    }
    .car-info {
        display: flex;

        .address-info {
            display: flex;
            gap: 10px;
        }

        .info-text {
            display: none;
        }

        .block-title {
            @include font(10px, normal, 500, none, normal, var(--color-default));
            @include adaptive-font(10, 10);
        }

        .address-info__icon {
            color: var(--color-default);
        }
    }

    &__item-page {
        padding: 10px 0;

        .car-info-dashboard {
            overflow: auto;
            @include scroll(var(--color-neutral-25), var(--color-neutral-100), 6px);
            max-height: 250px;
        }

        .car-info-dashboard__list {
            flex-direction: column;
        }

        .car-info-dashboard__icon {
            display: block;
            margin-right: 5px;
        }

        .car-info-dashboard__item {
            width: 100%;
        }

        .car-info-dashboard__text-wrap {
            display: flex;
            gap: 5px;
        }
    }

    &__notifications-badge {
        padding: 2px 5px;
        background: var(--color-secondary-500);
        @include font(8px, normal, 700, none, normal, var(--color-white));
        border-radius: 100%;
        position: absolute;
        top: -5px;
        left: 50%;
    }

    &__notifications {
        max-height: 74px;
        overflow: auto;
        @include scroll(var(--color-neutral-25), var(--color-neutral-100), 6px);

        &__item {
            padding-left: 30px;
            @include font(10px, normal, 400, none, normal, var(--color-font-description));
            @include adaptive-font(10, 10);
            display: flex;
            align-items: center;
            gap: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;

            span {
                &:last-child {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            //flex-wrap: wrap;
        }
    }

    &::v-deep(.dynamic-scroller-list-track) {
        height: auto;
    }

    &::v-deep(.car-info-dashboard) {
        padding: 0;
        .car-info-dashboard__list {
            display: grid;
            //justify-content: space-between;
            grid-template-columns: 1fr;
            justify-content: center;
            align-self: center;
            //flex-wrap: wrap;
            gap: 30px;
            padding: 0;
        }
        .car-info-dashboard__item {
            display: flex;
            align-items: flex-start;
            gap: 3px;
        }

        .sensors-title {
            @include font(12px, normal, 700, none, normal, var(--Primary-Text-in-Light-Mode));
            @include adaptive-font(12, 10)
        }

        .sensors-value {
            @include font(12px, normal, 400, none, normal, var(--Primary-Text-in-Light-Mode));
            @include adaptive-font(12, 10)
        }
        .car-info-dashboard__icon {
            display: none;
        }
    }

    #sectionInfoByPage {
        height: 100%;
        margin-top: -10px;

        .section__head {
            display: none;
        }
        .section__content-wrapper {
            grid-template-rows: 1fr 50px;
        }
        .section__body {
            grid-template-rows: auto 1fr;
            background: none;
        }
        .section__footer {
            box-shadow: none;
            background: none;
        }
        .btn-cancel {
            display: none;
        }
    }
}
.unit-info-prometheus-reefer {
    &__img-container {
        display: grid;
        grid-template-rows: minmax(auto, 165px);
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    &__body {
        padding: 0 20px 0 45px;
        display: grid;
        overflow: hidden;
    }

    &__line {
        height: 1px;
        width: 100%;
        background: linear-gradient(90deg, #AED0F3 0%, #007EFF 0.01%, rgba(255, 255, 255, 0) 81.43%, rgba(143, 198, 255, 0) 81.43%);
        margin: 30px 0;
    }

    &::v-deep(.car-info-sensor-address) {
        min-height: 60px;
        max-height: 60px;
        overflow: hidden;
    }
    &::v-deep(.sensors-value) {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
.show-info {
    width: 350px;
}
.show-info--close {
    width: 45px;
    overflow: hidden;

    .unit-info-prometheus-reefer__img-container {
        display: none;
    }

    #sectionInfoByPage {
        display: none;
    }
    .page-block {
        display: none;
    }
}
.button {
    &.button-info-open {
        position: absolute;
        left: 100%;
        top: 10px;
        width: 18px;
        height: 18px;
        background: black;
        z-index: 10;
    }
    &.button-close {
        z-index: 10;
        width: 13px;
        height: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.button-close-info {
        //background: none;
        background: #E8F1F8;
        box-shadow: 0px 2px 4px 0px rgba(69, 125, 191, 0.50);
        right: 10px;
        border-radius: 8px;
        .icon {
            //width: 24px;
            //height: 24px;
        }
        &:before,
        &:after {
            content: none;
        }
    }
}
</style>