import merge from "lodash/merge"

const productsName = {
    prometheus: 'prometheus',
    intuit: 'intuit'
}
const [product, site] = String(process.env.VUE_APP_PRODUCT).split('.', 2)
let app = {
    $product_name: String(process.env.VUE_APP_PRODUCT_NAME),
    $package: String(process.env.VUE_APP_PACKAGE),
    $ver: {product, site, name: process.env.VUE_APP_PRODUCT_NAME},
    $product: product,
    $site: site,
    $isPrometheus: product === productsName.prometheus,
    $isIntuit: product === productsName.intuit,
    $isAdminSite: String(process.env.VUE_APP_PACKAGE).includes('admin'),
    $isPortalSite: String(process.env.VUE_APP_PACKAGE).includes('portal'),
    $isDevelopment: (process.env.NODE_ENV !== 'production'),
}

let consts = {
    app,
    productsName,
    iconsPathFolders: {
        timeline: {
            units: 'img/playBackEvents/',
            shipment: 'img/shipments/'
        }
    },
    themes: {
        'white': 'theme-white',
        'dark': 'theme-dark',
    },
    siteName:{
        admin: 'admin',
        portal: 'portal'
    },
    default_route: '/',
    langs: [
        {id: 'en', name: 'English'},
        {id: 'es', name: 'Español'},
        {id: 'pt', name: 'Português'},
        {id: 'ar', name: 'عرب'},
    ],
    unitsOfMeasures: {
        'distanceUnits': "km",
        'speedUnits': "kph",
        //'altitudeUnits': "m",
        'volumeUnits': "l",
        // 'fuelEconomyUnits': "lPer100km",
        'temperatureUnits': "C",
        'lightingUnits': "Lx",
        'humidityUnits': "%",
        'batteryUnits': "%",
        'shockUnits': "%",
        // 'percentageUnits': "%",
        // 'electricalUnits': "V",
    },
    customizeDomains: ['portal', 'admin', 'hw'],
    timeFormats: [
        {php_format: 'HH:mm:ss',   name: 'HH:MM',   moment_js:'HH:mm'},
        {php_format: 'HH.mm.ss',   name: 'HH.MM',   moment_js:'HH.mm'},
        {php_format: 'hh:mm:ss a', name: 'HH:MM A', moment_js:'hh:mm A'},
        {php_format: 'a hh:mm:ss', name: 'A HH:MM', moment_js:'A hh:mm'},
    ],
    timeFormatsWithSeconds: {
        'HH:mm': 'HH:mm:ss',
        'HH.mm': 'HH.mm.ss',
        'hh:mm A': 'hh:mm:ss A',
        'A hh:mm': 'A hh:mm:ss',
    },
    dateFormats: [
        // altFormat - only flatpickr - https://flatpickr.js.org/options/
        {php_format: 'MM/dd/yyyy', name: 'MM/DD/YYYY', moment_js:'MM/DD/YYYY', short: 'MMM D' , altFormat: 'M/j/Y'},
        //{php_format: 'MM-dd-yyyy', name: 'MM-DD-YYYY', moment_js:'MM-DD-YYYY', short: 'MMM D', altFormat: 'M-j-Y'},
        {php_format: 'MM.dd.yyyy', name: 'MM.DD.YYYY', moment_js:'MM.DD.YYYY', short: 'MMM D', altFormat: 'M.j.Y'},
        //{php_format: 'dd-MM-yyyy', name: 'DD-MM-YYYY', moment_js:'DD-MM-YYYY', short: 'D MMM', altFormat: 'j-M-Y'},
        {php_format: 'dd/MM/yyyy', name: 'DD/MM/YYYY', moment_js:'DD/MM/YYYY', short: 'D MMM', altFormat: 'j/M/Y'},
        {php_format: 'dd.MM.yyyy', name: 'DD.MM.YYYY', moment_js:'DD.MM.YYYY', short: 'D MMM', altFormat: 'j.M.Y'},
        //{php_format: 'dd MM yyyy', name: 'DD MM YYYY', moment_js:'DD MM YYYY', short: 'D MMM', altFormat: 'M j Y'},
        {php_format: 'yyyy-MM-dd', name: 'YYYY-MM-DD', moment_js:'YYYY-MM-DD', short: 'D MMM', altFormat: 'Y-M-j'},
        {php_format: 'yyyy/MM/dd', name: 'YYYY/MM/DD', moment_js:'YYYY/MM/DD', short: 'D MMM', altFormat: 'Y/M/j'},
    ],
    colorBluetoothStatuses: {
        low: '#FF403D',
        medium: '#FF9211',
        high: '#28B25F',
        default: '#415C84',
    },
    // temperatureUnitsList:[
    //     {id: 'C', name: 'C'},
    //     {id: 'F', name: 'F'},
    //     //{id: 'K', name: 'K'},
    // ],
    fetchAppProps: {
        dispatches: [
            {name: 'fetchUserSettings', params: {}},
            {name: 'fetchUnitsIcons', params: {}},
            {name: 'fetchUnitsHwTypesAll', params: {}},
            {name: 'fetchUnitsTypes', params: {}},

            {name: 'fetchAppUser', params: {}},
        ],
    },
    reloadAppProps: {
        dispatches: [
        ],
        commits: [
        ],
    },
    sensorsSettings: {
        battery: {
            color: 'var(--Selected-Element)',
        },
        temperature: {
            color: 'var(--Selected Element)',
        },
        supply_air_temperature: {
            color: 'var(--Violet-Primary-Color)',
        },
        external_temperature: {
            color: 'var(--Orange-Primary-Color)'
        },
        fuel_level: {
            color: 'var(--Yellows-Primary-Color)'
        },
    },
    fetchByTimer: {
        dispatches: [
            //{name: 'fetchUserSettingsChanged', eachT: 30, params: {}},
            //{name: 'fetchUnitsIconsChanged', eachT: 30, params: {}},
            {name: 'fetchUnitsHwTypesChanged', eachT: 30, params: {}},
            //{name: 'fetchUnitsTypesChanged', eachT: 30, params: {}},

            //{name: 'fetchAppUserChanged', eachT: 30, params: {}},
        ],
    },
    status: {
        // 0- 5 min - online
        // 5-15 min - online+
        //15-60 min - offline
        //   60+min - offline+
        online: 5 * 60,
        // 'online+': 15 * 60,
        offline: 15 * 60,
        // 'offline+': 60 * 60,
    },
    donutItemNull: {name: '', value: -1, tooltipItemList: [''] , color:'#cccccc'},
    classSensors:{
        value: 'sensor-value',
        no_value: 'sensor-no-value',
    },
    statusDefaultParams: {
        keys: {
            name: 'keys',
            color: '#00D285',
        },
        orders: {
            name: 'orders',
            color: '#FF5A5A',
        },
        created: {
            name: 'created',
            color: '#00D285',
        },
        removed: {
            name: 'removed',
            color: '#FF5A5A',
        },
        active: {
            name: 'active',
            color: '#00D285',
        },
        available: {
            name: 'available',
            color: '#00D285',
        },
        used: {
            name: 'used',
            color:'#4FA8F6',
        },
        transferred: {
            name: 'transferred',
            color: '#FD7F23',
        },
        revoked: {
            name: 'revoked',
            color: '#FF403D',
        },
        parked: {
            name: 'parked',
            color: '#4FA8F6',
        },
        moving: {
            name: 'moving',
            color: '#3847D0',
        },
        mileage: {
            name: 'mileage',
            color: '#3847D0',
        },
        idling: {
            name: 'idling',
            color: '#3847D0',
        },
        offline: {
            name: 'offline',
            color: '#FF403D',
        },
        online: {
            name: 'online',
            color: '#4FA8F6'
        },
        deleted:{
            name: 'deleted',
            color: '#F76059'
        }
    },
    dataPeriod: [
        {id: 'this_day', name: 'Today', sort: 1},
        {id: 'this_week', name: 'This Week', sort: 2},
        {id: 'prev_week', name: 'Prev Week', sort: 3},
        {id: 'this_month', name: 'This Month', sort: 4},
        {id: 'prev_month', name: 'Prev Month', sort: 5},
        {id: '3_month', name: '3 Month', sort: 6},
        {id: '6_month', name: '6 Month', sort: 7},
        {id: '12_month', name: '1 Year', sort: 8},
    ],
    dataTotal: [
        {id: 'total', name: 'Total'},
    ],
    divisionScale: [
        {id: 'day', name: 'Days', sort: 1},
        {id: 'week', name: 'Week', sort: 2},
        {id: 'month', name: 'Month', sort: 3},
        {id: 'year', name: 'Year', sort: 4},
    ],
    defaultSettingsLayers: {
        units: {visible: false, showInList: true, showLayer: true},
        unit_edit: {visible: false, showInList: false, showLayer: false},
        unit_zone: {visible: false, showInList: false, showLayer: false},
        units_groups: {visible: false, showInList: false, showLayer: false},
        geoitems: {visible: false, showInList: true, showLayer: true},
        geoitem_edit: {visible: false, showInList: false, showLayer: false},
        tracks: {visible: false, showInList: false, showLayer: false},
        track_by_points: {visible: false, showInList: false, showLayer: false},
        notifications: {visible: false, showInList: false, showLayer: false},
        shipments_tracks: {visible: false, showInList: false, showLayer: false},
        shipments_units: {visible: false, showInList: false, showLayer: false},
        smartSearch: {visible: false, showInList: false, showLayer: false},
    },
    api: {
        defaults: {
            timeout: 3*60 * 1000,//180 sec
            auth: {
                timeout: 3*60 * 1000,//180 sec
            },
        },

        user: {
            // fields: ['id',],
            expand: [],
        },
        users: {
            // fields: ['id',],
            expand: ['access_right'],
        },

        unit: {
            fields: ['id',],
            expand: ['access_right'],
        },
        units: {
            // fields: ['id',],
            expand: ['access_right'],
        },
        units_groups: {
            // fields: ['id',],
            expand: ['access_right'],
        },

        geopoints: {
            //fields: [],
            expand: ['access_right'],
        },
        geopoints_groups: {
            // fields: ['id',],
            expand: ['access_right'],
        },

        geozones: {
            //fields: [],
            expand: ['access_right'],
        },
        geozones_groups: {
            // fields: ['id',],
            expand: ['access_right'],
        },

        notifications: {
            //fields: [],
            expand: [],
        },

        hwtypes: {
            //fields: ['id',],
            expand: [],
        },
        weathers: {
            //fields: ['id',],
            expand: [],
        },
    },
    fieldConversion:{
        unit: {
            unit_id: 'Unit',
            name: 'Name',
            creator: 'Creator',
            hw_id: 'IMEI',
            hw_type: 'Hardware',
            reefer_type: 'Reefer type',
            type: 'Type',
            phone: 'Phone',
            icon: 'Icon',
            mark: 'Make',
            model: 'Model',
            vin: 'Vin',
            platenumber: 'Number Plate',
            photo: 'Photo',
            inputs: 'Inputs',
            outputs: 'Outputs',
            ignition: 'Ignition',
            max_speed: 'Speed Limit',
            batt_trigger: 'Battery Notification',
            speed_trigge: 'Speed Notification',
        },
        user:{
            creator: 'Creator',
            login: 'Login',
            time_zone: 'Time Zone',
            email: 'email',
            phone: 'Phone',
            legal_name: 'Company Name',
            units_of_measure: 'Measure',
            dateFormat: 'Date Format',
            timeFormat: 'Time Format',
            trackSpeedColors: 'Color Track by Speed',
            notifications: 'Notifications',
            UnitsOfMeasure: 'Measure',
            // speedUnits: '',
            // volumeUnits: '',
            // altitudeUnits: '',
            // temperatureUnits: '',
            url: 'DNS',
            favicon: 'Favicon',
            login_logo: 'Logo',
            site_name: 'Site Name',
            bg_login: 'Background',
            head__logo: 'Head Logo',
            status: 'Status',
            timeOffset: 'Time of set',
            dst: 'Daylight saving time',
            is_admin: 'Dealer',
            password: 'Password',
        },
        system: {
            user_ip: 'IP',
            referrer: 'DNS',
        },
        geozone:{
            name: 'Name',
            descr: 'Description',
            type: 'Type',
            creator: 'Creator',
            radius: 'Radius',
            color: 'Color',
        },
        command:{
            type: 'Type',
            cmd_reg_time: 'Registration time',
            info: 'Info',
            subtype: 'Sub type',
        },
    },
    entri: {
        locales: ['en', 'es', 'pt', 'pt-br', 'pt-pt', 'fr', 'it', 'de', 'nl', 'pl', 'tr', 'ja'],
        add_domains: {
            admin: {
                type: "CNAME",
                host: "admin",
                value: "{CNAME_TARGET}",
                applicationUrl: "admin.intuitconnect.us",
                ttl: 300,
                ssl: true
            },
            portal: {
                type: "CNAME",
                host: "portal",
                value: "{CNAME_TARGET}",
                applicationUrl: "portal.intuitconnect.us",
                ttl: 300,
                ssl: true
            },
            hw: {
                type: "A",
                host: "hw",
                value: "157.230.63.150",
                // applicationUrl: "hw.intuitconnect.us",
                ttl: 300,
            },
            TXT: {
                type: "TXT",
                host: "@",
                value: "v=spf1 a mx include:spf.protection.outlook.com ip4:135.148.236.180/32 ip4:135.148.236.181/32 ~all",
                ttl: 300,
            }
        },
        "whiteLabel": {
            "hideEntriLogo": true,
            "hideConfetti": true,
            "logo": "https://privatelabel.xgps.us/img/dns/user.0008/favicon.ico?h=",
            "theme": {
                "fg": "#fff",
                "bg": "#027afd"
            },
            //"logoBackgroundColor": "#444444",
            "removeLogoBorder": true,
            "customCopy": {
                "initialScreen": {
                    "title": {
                        "en": "Private Label Made Easy",
                        "es": "Personalización de Marca Simplificada",
                        "pt": "Personalização de Marca Simplificada",
                    },
                    "subtitle": {
                        "en": "",
                        "es": "",
                        "pt": "",
                    }
                },
                // "manuallyScreen": {
                //     "disableManualSetupDocumentationLink": true,
                //     "stepByStepGuide": {
                //         "en": "Follow our <link>step-by-step</link> guide",
                //         "es": "Sigue nuestra guía <link>paso-a-paso</link>",
                //         "pt": "Sigue nuestra guía <link>paso-a-paso</link>",
                //     }
                // }
            }
        },
    },
    cameraTypesName: {
        howen: "howen"
    },
    components: {
        map: {
            paddingBounds: {
                paddingTopLeft: [100, 100],
                paddingBottomRight: [100, 100],
            }
        },
        LiveVideo: {
            slideshow:{
                default: 1,//sec
                error: 10,//sec
            },
            timeout: 60,//sec
        },
        SegmentControl_slider: {
            slideOptions: {
                slidesPerView: 2,
                spaceBetween:5,
                breakpoints: {
                    370: {
                        slidesPerView: 4,
                    }
                },
                // Any other options that can be got from plugin documentation
            },
        }
    },
    shipment: {
        mods: ['road','air','ocean','rail'],
        extendedShipmentByStatus: ['completed', 'cancelled'],
        route: {
            radius: 300,
            color: {
                start: '#047AFBFF',
                end: '#28B25FFF',
            }
        },
    }
}

//intuit
import consts_intuit from './intuit..js'
if (String(process.env.VUE_APP_PACKAGE).includes('intuit')) consts = merge(consts, consts_intuit)
import consts_intuit_portal from './intuit.portal.js'
if (process.env.VUE_APP_PACKAGE === 'intuit.portal') consts = merge(consts, consts_intuit_portal)
import consts_intuit_admin from './intuit.admin.js'
if (process.env.VUE_APP_PACKAGE === 'intuit.admin') consts = merge(consts, consts_intuit_admin)

//prometheus
import consts_prometheus from './prometheus..js'
if (String(process.env.VUE_APP_PACKAGE).includes('prometheus')) consts = merge(consts, consts_prometheus)
import consts_prometheus_portal from './prometheus.portal.js'
if (process.env.VUE_APP_PACKAGE === 'prometheus.portal') consts = merge(consts, consts_prometheus_portal)
import consts_prometheus_admin from './prometheus.admin.js'
if (process.env.VUE_APP_PACKAGE === 'prometheus.admin') consts = merge(consts, consts_prometheus_admin)

export default consts
