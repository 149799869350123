// import consts from "@/consts"
import i18n from "@/i18n";
import {getApiProps} from "@/lib/lib";

export default {
    state: {
        accidents_index: [],
        accidents: [],
    },
    actions: {
        fetchLastAccidents({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLastAccidents', time: Date.now() / 1000})

                let params = getApiProps('accidents', {withAddress: 1, ...args.params})
                this.$api.accidents.getLast(args.last, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeAccidents', response.data)

                            //forEach
                            //dispatch('fetchVideoRequest', response?.data?.media_request_id)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLastAccidents', inprogress: false})
                    });
            })
        },
        fetchAccidents4Date({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                if (!getters.apiToken || !(args.date > '')) {//!args.unitId ||
                    return reject(false)
                }

                // if(args.unitId > 0) {
                //     let index = getters.accidentsIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
                //     if (index) {
                //         return resolve(true)
                //     }
                // }

                dispatch('setLastCall', {name: 'fetchAccidents4Date', time: Date.now() / 1000})
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastAccidents', time: Date.now() / 1000})
                }

                let params = getApiProps('accidents', {withAddress: 1, ...args.params})
                this.$api.accidents.get4Date(args.unitId, args.date, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeAccidents', response.data)
                            // commit('setAccidentsIndex', {...args, count: response.data.length})
                            // if (!args.unitId && response.data.length) {
                            //     response.data
                            //         .map(e => e.unit_id)
                            //         .filter(arrayFilter_OnlyUnique)
                            //         .forEach(unitId => {
                            //             commit('setAccidentsIndex', {...args, unitId, count: 1})
                            //         })
                            // }

                            //forEach
                            response.data.forEach(accident => {
                                dispatch('fetchVideoRequest', {id: accident?.media_request_id})
                            })
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchAccidents4Date', inprogress: false})
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastAccidents', inprogress: false})
                        }
                    });
            })
        },
        fetchAccidents4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                    return reject(false)
                }

                // if(args.unitId > 0) {
                //     let request = getters.getAccidents.find(e => (e.unit_id == args.unitId && e.time?.utc >= args.from && e.time?.utc <= args.to))
                //     if (request) {
                //         return resolve(true)
                //     }
                // }

                dispatch('setLastCall', {name: 'fetchAccidents4Period', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                // if (args.date == getters.getDateToday) {
                //     dispatch('setLastCall', {name: 'fetchLastAccidents', time: Date.now() / 1000})
                // }

                let params = getApiProps('accidents', {withAddress: 1, ...args.params})
                this.$api.accidents.get4Period(args.unitId, args.from, args.to, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeAccidents', response.data)
                            // commit('setAccidentsIndex', {...args, count: response.data.length})
                            // if (!args.unitId && response.data.length) {
                            //     response.data
                            //         .map(e => e.unit_id)
                            //         .filter(arrayFilter_OnlyUnique)
                            //         .forEach(unitId => {
                            //             commit('setAccidentsIndex', {...args, unitId, count: 1})
                            //         })
                            // }

                            //forEach
                            //dispatch('fetchVideoRequest', response?.data?.media_request_id)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchAccidents4Period', inprogress: false})
                        /* getters.getTimeToday+24h and args.from/to intersect*/
                    });
            })
        },
        reloadAccidents({commit, dispatch}, args) {
            commit('clearAccidents')
            return dispatch('fetchAccidents4Date', {date: 'today', ...args})
        },

        createAccident({commit, getters}, accident) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }

                //const params = {expand: 'MediaRequest'} //getApiProps('accidents')
                this.$api.accidents.create(accident.unitId, accident) //, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeAccidents', [response.data])
                            //dispatch('fetchVideoRequest', response?.data?.media_request_id)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        accidentPush2Driver({getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id) {
                    return reject(false)
                }

                //const params = getApiProps('accidents')
                this.$api.accidents.push2Driver(args.unitId, args.id, args.type, args)//, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setAccidentsIndex(state, params) {
            let i = state.accidents_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.accidents_index.push(Object.freeze({...params}))
            } else {
                state.accidents_index[i] = Object.freeze({...state.accidents_index[i], ...params})
            }
        },
        storeAccidents(state, nRequests) {
            if (!state.accidents.length) {
                state.accidents = nRequests.map(r => Object.freeze(r))
                return true
            }

            nRequests.forEach(function (nRequest) {
                let i = state.accidents.findIndex(r => (r.xid == nRequest.xid))
                if (i < 0) {
                    if(!nRequest.removed_at) state.accidents.push(Object.freeze(nRequest))
                } else {
                    // updateObjectByDiff(state.accidents[i], nRequest)
                    if(nRequest?.removed_at) {
                        state.accidents.splice(i , 1)
                    } else {
                        delete nRequest.xid
                        state.accidents[i] = Object.freeze({...state.accidents[i], ...nRequest})
                    }
                }

            })
        },
        clearAccidents(state) {
            state.accidents = [];
            state.accidents_index = [];
        },
    },
    getters: {
        getNewAccidentsCount(state, getters) {
            let lastVisit = getters.getLastVisitNotifications
            if (getters.getTimeToday > lastVisit) lastVisit = getters.getTimeToday
            return state.accidents.filter(e => e.time?.utc > lastVisit).length
        },
        getAccidentsIndex(state) {
            return state.accidents_index
        },
        getAccidents(state) {
            return state.accidents
        },
        getAccidentsI18nMessages(state) {
            return state.accidents
                .map((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'accident' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    return {type: event.type, xid: event.xid, message};
                })
        },
        getAccidentsI18nMessagesById(state) {
            return state.accidents
                .reduce((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'accident' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    eventsTextByXid[event.xid] = message;// || i18n.global.t('message.Unknown')//???
                    return eventsTextByXid;
                }, {})
        },
        getAccidentsByIds(state) {
            return state.accidents.reduce((out, request) => {
                out[request.id] = request
                return out
            }, {})
        },
    }
}
