<template>
    <div class="group-input-array"
         :class="$attrs.class"
         v-for="(item,i) in (modelValue || [])"
         :key="'group-input-'+i+index"
    >
        <FieldGroup_input
            :id="'group-input-'+i+index"
            :label="label"
            :error="(errors || [])[i]"
            v-model="value[i]"
            :data-x="item"
        />
        <!-- {{item}} -->
        <button class="button button_view_icon button_theme_base ml__8" style="display: block"
                v-if="i == modelValue?.length-1 && value[i] > '' && (!maxItems || (i < (maxItems-1) && modelValue[i]?.length))"
                @click="addValue()"
        >
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__add_lg"/></span> </span>
        </button>
        <button class="button button_view_icon button_theme_base is_danger ml__8"
                @click="removeValue(i)"
        >
            <span class="icon"><span class="icon__symbol"><SvgIcon class="icon" name="common__delete_lg"/></span> </span>
        </button>
    </div>
</template>

<script>
export default {
    name: "FieldGroup_array_inputs",
    emits: ['update:modelValue', 'actionAdd', 'actionRemove'],
    props: {
        modelValue: Array,
        errors: Array,
        index: [String, Number],
        label: {
            type: String,
            default: () => ''
        },
        maxItems: {
            type: Number,
            default: () => 5
        },
    },
    computed: {
        value() {
            return this.modelValue
        }
    },
    watch: {
        value: {
            handler: function (nVal) {
                this.$emit('update:modelValue', nVal)
            },
            deep: true
        }
    },
    methods: {
        removeValue(i){
            let value = [...this.modelValue];
            value.splice(i,1)
            this.$emit('update:modelValue', value)
            if(!value?.length){
                this.$emit('update:modelValue', [''])
            }
            this.$emit('actionRemove')
        },
        addValue(){
            if(!this.maxItems || (this.modelValue?.length<this.maxItems)) this.$emit('update:modelValue', [...this.modelValue, ''])
            this.$emit('actionAdd')
        },
    }
}
</script>

<style scoped lang="scss">
.group-input-array {
    display: flex;
    align-items: baseline;
}
</style>