<template>
    <span  class="description shipment-date">
        {{date}}
    </span>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    name: "ShipmentList_completed_date",
    props: {
        shipmentId: [String, Number],
    },
    computed: {
        ...mapGetters([
            "getShipments",
            "getDateFormat",
            "getTimeFormat",
        ]),
        shipment() {
            return this.getShipments?.find(s => s.id == this.shipmentId);
        },
        date() {
            return moment(this.shipment?.completed__time?.date).format(this.getDateFormat + ' ' + this.getTimeFormat)
        }
    }
}
</script>

<style scoped lang="scss">
.shipment-date {
    @include font(10px, normal, 700, none, normal, var(--shipment-date));
    white-space: nowrap;
}
</style>