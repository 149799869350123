<template>
    <div class="list__button-group" >
        <template v-for="(command, i) in menuCommands" :key="i">
            <component :is="'ShipmentList_menu_'+command.name"
                       :disabled="command.disabled"
                       :shipmentId="shipmentId"
                       v-if="command.view"
            />
        </template>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations,} from "vuex";
    import { accessRight } from "@/lib/access-rights";

    
    import ShipmentList_menu_edit from "@/components/shipment/ShipmentList_menu_edit.vue";
    //import ShipmentList_menu_reports from "@/components/shipment/ShipmentList_menu_reports.vue";
    import ShipmentList_menu_delete from "@/components/shipment/ShipmentList_menu_delete.vue";

    export default {
        name: 'ShipmentsList_shipment_menu',
        inject: ['setRoute'],
        props: {
            shipmentId: [String, Number],
            commands: {
                type: [Array, Object],
                default: () => [
                    'edit',
                    // 'delete',
                ]
            }
        },
        components: {
            ShipmentList_menu_edit,
            //ShipmentList_menu_reports,
            ShipmentList_menu_delete,
        },
        data() {
            return {
                accessRight,
                defCommands: [
                    // {
                    //     name: 'reports',
                    //     view: () => this.shipment.status === 'completed',
                    //     // computedView: 'isShipmentAccessRight_edit',
                    //     disabled: false,
                    //     // computedDisabled: 'isShipmentsNotFullLoad',
                    // },
                    {
                        name: 'edit',
                        view: true,
                        // computedView: 'isShipmentAccessRight_edit',
                        disabled: false,
                        // computedDisabled: 'isShipmentsNotFullLoad',
                    },
                    {
                        name: 'delete',
                        view: true,
                        // computedView: 'isShipmentAccessRight_edit',
                        disabled: false,
                        // computedDisabled: 'isShipmentsNotFullLoad',
                    },
                ],
            }
        },
        computed: {
            ...mapGetters([
                "getAppUser",
                "getAppMenus",
                "getShipments",
                "isShipmentsFullLoad",
                "getHwTypesByType",
            ]),
            routeName() {
                return this.$route.name
            },
            routeFilter() {
                return this.$route.params
            },
            routeQueryFilter() {
                return this.$route.query
            },
            isShipmentsNotFullLoad () {
                return !this.isShipmentsFullLoad
            },
            shipment() {
                return this.getShipments?.find(s => s.id == this.shipmentId)
            },
            menuCommands() {
                return this.commands.map(c => {
                    if(typeof c === 'string'){
                        c = this.defCommands.find(cmd => cmd.name == c)
                    }
                    let disabled = c?.computedDisabled ? this[c.computedDisabled] :
                        typeof c.disabled == 'function' ? c.disabled() : c.disabled
                    let view = c.computedView ? this[c.computedView] :
                        typeof c.view == 'function' ? c.view() : c.view
                    return {...c, disabled, view}
                })
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
        },
    }
</script>

<style scoped>

</style>