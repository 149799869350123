// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a?.fromDateTime?.utc - b.a?.fromDateTime?.utc;
}

export default {
    state: {
        shipmentsAlertsFullLoad: false,
        shipmentsAlertsLiteLoad: false,
        shipmentsAlerts: [],
    },
    actions: {
        fetchShipmentsAlerts/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                let id = args.id
                if (!getters.apiToken || !id) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentsAlerts', time: Date.now() / 1000})

                const params = getApiProps('shipmentsAlerts', args)
                this.$api.shipmentsAlerts.shipmentAlerts(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsAlerts', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setShipmentsAlertsLiteLoad', true)
                        commit('setShipmentsAlertsFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchShipmentsAlerts', inprogress: false})
                    });
            })
        },


    },
    mutations: {
        setShipmentsAlertsFullLoad(state, FullLoad) {
            state.shipmentsAlertsFullLoad = state.shipmentsAlertsFullLoad || FullLoad
        },
        setShipmentsAlertsLiteLoad(state, LitaLoad) {
            state.shipmentsAlertsLiteLoad = state.shipmentsAlertsLiteLoad || LitaLoad
        },

        setShipmentsAlerts(state, nShipmentsAlerts) {
            nShipmentsAlerts = nShipmentsAlerts.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nShipmentsAlerts.sort(sortByName)
            state.shipmentsAlerts = nShipmentsAlerts
        },

        updateShipmentsAlerts(state, nShipmentsAlerts) {
            if (!state.shipmentsAlerts.length) {
                nShipmentsAlerts = nShipmentsAlerts.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nShipmentsAlerts.sort(sortByName)
                state.shipmentsAlerts = nShipmentsAlerts
                // const chunks = arraySplitIntoChunks(nShipmentsAlerts)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.shipmentsAlerts.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nShipmentsAlerts.forEach(function (nShipmentAlert) {
                if (nShipmentAlert?.name) nShipmentAlert.name_ = nShipmentAlert.name.toLocaleLowerCase()
                let i = state.shipmentsAlerts.findIndex(u => (u.xid == nShipmentAlert.xid))
                if (i < 0) {
                    state.shipmentsAlerts.push(nShipmentAlert) //(Object.freeze(nShipmentAlert))
                } else {
                    // if (!state.shipmentsAlertsFullLoad || state.shipmentsAlerts[i][changedField] != nShipmentAlert[changedField]) {
                    updateObjectByDiff(state.shipmentsAlerts[i], nShipmentAlert)
                    // delete nShipmentAlert.xid
                    // nShipmentAlert = {...state.shipmentsAlerts[i], ...nShipmentAlert}
                    // state.shipmentsAlerts[i] = nShipmentAlert //Object.freeze(nShipmentAlert)
                }
            })

        },
        filterShipmentsAlerts(state, nShipmentsAlerts) {
            // let Ids = state.shipmentsAlerts.map(u=> u.xid)
            let nIds = nShipmentsAlerts.map(u => u.xid)
            let removedIds = state.shipmentsAlerts.filter(u => !nIds.includes(u.xid)).map(u => u.xid)
            removedIds.forEach(removedId => {
                let i = state.shipmentsAlerts.findIndex(u => (u.xid == removedId))
                if (i != -1) {
                    state.shipmentsAlerts.splice(i, 1)
                }
            })
        },
        updateShipmentsAlert(state, nShipmentAlert) {
            if (nShipmentAlert?.name) nShipmentAlert.name_ = nShipmentAlert.name.toLocaleLowerCase()
            let i = state.shipmentsAlerts.findIndex(u => (u.xid == nShipmentAlert.xid))
            if (i < 0) {
                state.shipmentsAlerts.push(nShipmentAlert) //(Object.freeze(nShipmentAlert))
            } else {
                // if (state.shipmentsAlertsFullLoad || state.shipmentsAlerts[i][changedField] != nShipmentAlert[changedField]) {
                updateObjectByDiff(state.shipmentsAlerts[i], nShipmentAlert)
                // delete nShipmentAlert.xid
                // nShipmentAlert = {...state.shipmentsAlerts[i], ...nShipmentAlert}
                // state.shipmentsAlerts[i] = nShipmentAlert //Object.freeze(nShipmentAlert)
            }
        },

        clearShipmentsAlerts(state) {
            state.shipmentsAlerts = []
            state.shipmentsAlertsFullLoad = false
        },
    },
    getters: {
        isShipmentsAlertsFullLoad(state) {
            return state.shipmentsAlertsFullLoad
        },
        isShipmentsAlertsLiteLoad(state) {
            return state.shipmentsAlertsLiteLoad
        },
        getShipmentsAlerts(state) {
            return state.shipmentsAlerts
        },
    }
}
