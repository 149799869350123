// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a?.time?.utc - b.a?.time?.utc;
}

export default {
    state: {
        shipmentsTracksFullLoad: false,
        shipmentsTracksLiteLoad: false,
        shipmentsTracks: [],
    },
    actions: {
        fetchShipmentsTracks/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                let id = args.id;
                if (!getters.apiToken || !id) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentsTracks', time: Date.now() / 1000})

                const params = getApiProps('shipmentsTracks', args)
                this.$api.shipmentsTracks.shipmentTrack(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            commit('updateShipmentsTracks', response.data)
                            resolve(response.data)
                        } else {
                            resolve(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setShipmentsTracksLiteLoad', true)
                        commit('setShipmentsTracksFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchShipmentsTracks', inprogress: false})
                    });
            })
        },
    },
    mutations: {
        setShipmentsTracksFullLoad(state, FullLoad) {
            state.shipmentsTracksFullLoad = state.shipmentsTracksFullLoad || FullLoad
        },
        setShipmentsTracksLiteLoad(state, LitaLoad) {
            state.shipmentsTracksLiteLoad = state.shipmentsTracksLiteLoad || LitaLoad
        },

        setShipmentsTracks(state, nShipmentsTracks) {
            nShipmentsTracks = nShipmentsTracks.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nShipmentsTracks.sort(sortByName)
            state.shipmentsTracks = nShipmentsTracks
        },

        updateShipmentsTracks(state, nShipmentsTracks) {
            if (!state.shipmentsTracks.length) {
                nShipmentsTracks = nShipmentsTracks.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nShipmentsTracks.sort(sortByName)
                state.shipmentsTracks = nShipmentsTracks
                // const chunks = arraySplitIntoChunks(nShipmentsTracks)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.shipmentsTracks.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nShipmentsTracks.forEach(function (nShipmentTrack) {
                if (nShipmentTrack?.name) nShipmentTrack.name_ = nShipmentTrack.name.toLocaleLowerCase()
                let i = state.shipmentsTracks.findIndex(u => (u.xid == nShipmentTrack.xid))
                if (i < 0) {
                    state.shipmentsTracks.push(nShipmentTrack) //(Object.freeze(nShipmentTrack))
                } else {
                    // if (!state.shipmentsTracksFullLoad || state.shipmentsTracks[i][changedField] != nShipmentTrack[changedField]) {
                    updateObjectByDiff(state.shipmentsTracks[i], nShipmentTrack)
                    // delete nShipmentTrack.xid
                    // nShipmentTrack = {...state.shipmentsTracks[i], ...nShipmentTrack}
                    // state.shipmentsTracks[i] = nShipmentTrack //Object.freeze(nShipmentTrack)
                }
            })

        },
        filterShipmentsTracks(state, nShipmentsTracks) {
            // let Ids = state.shipmentsTracks.map(u=> u.xid)
            let nIds = nShipmentsTracks.map(u => u.xid)
            let removedIds = state.shipmentsTracks.filter(u => !nIds.includes(u.xid)).map(u => u.xid)
            removedIds.forEach(removedId => {
                let i = state.shipmentsTracks.findIndex(u => (u.xid == removedId))
                if (i != -1) {
                    state.shipmentsTracks.splice(i, 1)
                }
            })
        },
        updateShipmentsTrack(state, nShipmentTrack) {
            if (nShipmentTrack?.name) nShipmentTrack.name_ = nShipmentTrack.name.toLocaleLowerCase()
            let i = state.shipmentsTracks.findIndex(u => (u.xid == nShipmentTrack.xid))
            if (i < 0) {
                state.shipmentsTracks.push(nShipmentTrack) //(Object.freeze(nShipmentTrack))
            } else {
                // if (state.shipmentsTracksFullLoad || state.shipmentsTracks[i][changedField] != nShipmentTrack[changedField]) {
                updateObjectByDiff(state.shipmentsTracks[i], nShipmentTrack)
                // delete nShipmentTrack.xid
                // nShipmentTrack = {...state.shipmentsTracks[i], ...nShipmentTrack}
                // state.shipmentsTracks[i] = nShipmentTrack //Object.freeze(nShipmentTrack)
            }
        },

        clearShipmentsTracks(state) {
            state.shipmentsTracks = []
            state.shipmentsTracksFullLoad = false
        },
    },
    getters: {
        isShipmentsTracksFullLoad(state) {
            return state.shipmentsTracksFullLoad
        },
        isShipmentsTracksLiteLoad(state) {
            return state.shipmentsTracksLiteLoad
        },
        getShipmentsTracks(state) {
            return state.shipmentsTracks
        },
    }
}
