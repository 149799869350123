const RESOURCE_NAME = '/units';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    remove(id, params) {
        return axios.delete(`${RESOURCE_NAME}/${id}`, {params});
    },
    restore(id, params) {
        return axios.put(`${RESOURCE_NAME}/${id}/restore`, {}, {params});
    },
    deletePermanently(id, params) {
        return axios.delete(`${RESOURCE_NAME}/${id}?permanently`, {params});
    },
    getFull(id, params) {
        return axios.get(`${RESOURCE_NAME}/${id}?expand=counters,values`, {params});
    },
    getProps(id, action, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/${action}`, {params});
    },
    getLmsg(id, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/lmsg`, {params});
    },

    updateCounters(id, data, params) {
        return axios.put(`${RESOURCE_NAME}/${id}/counters`, data, {params});
    },
    sendCmd(id, data, params) {
        return axios.put(`${RESOURCE_NAME}/${id}/send-cmd`, data, {params});
    },
    getLastCmd(id, last, params) {
        return axios.get(`unit/${id}/commands/last/${last}`, {params});
    },
    getAllCmd(id, params) {
        return axios.get(`unit/${id}/commands`, {params});
    },
    liveVideo(id, data, params) {
        return axios.put(`${RESOURCE_NAME}/${id}/live-video`, data, {params});
    },
    weather(id, data, params) {
        return axios.put(`${RESOURCE_NAME}/${id}/weather`, data, {params});
    },

    getLmsgs(data, params) {
        return axios.post(`${RESOURCE_NAME}/lmsgs`, data, {params});
    },

    getIcons() {
        return axios.get(`${RESOURCE_NAME}/icons`);
    },
    getHwTypes() {
        return axios.get(`${RESOURCE_NAME}/hw-types`);
    },
    getTypes() {
        return axios.get(`${RESOURCE_NAME}/types`);
    },
});