// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        shipmentsTemplatesFullLoad: false,
        shipmentsTemplatesLiteLoad: false,
        shipmentsTemplates: [],
    },
    actions: {
        fetchShipmentsTemplates/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentsTemplates', time: Date.now() / 1000})

                const params = getApiProps('shipmentsTemplates', args)
                this.$api.shipmentsTemplates.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsTemplates', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setShipmentsTemplatesLiteLoad', true)
                        commit('setShipmentsTemplatesFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchShipmentsTemplates', inprogress: false})
                    });
            })
        },

        saveShipmentsTemplate({dispatch}, shipmentsTemplate) {
            let fn = (shipmentsTemplate.id) ? 'updateShipmentsTemplate' : 'createShipmentsTemplate'
            return dispatch(fn, shipmentsTemplate);
        },
        createShipmentsTemplate({commit}, shipmentsTemplate) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsTemplates')
                this.$api.shipmentsTemplates.create(shipmentsTemplate, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateShipmentsTemplate({commit}, shipmentsTemplate) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsTemplates')
                this.$api.shipmentsTemplates.update(shipmentsTemplate.id, shipmentsTemplate, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteShipmentsTemplate({commit}, shipmentsTemplateId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsTemplates')
                this.$api.shipmentsTemplates.delete(shipmentsTemplateId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (!response.data || !response.data.error) {
                            if (!response.data) commit('deleteShipmentsTemplate', shipmentsTemplateId)
                            else commit('updateShipmentsTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setShipmentsTemplatesFullLoad(state, FullLoad) {
            state.shipmentsTemplatesFullLoad = state.shipmentsTemplatesFullLoad || FullLoad
        },
        setShipmentsTemplatesLiteLoad(state, LitaLoad) {
            state.shipmentsTemplatesLiteLoad = state.shipmentsTemplatesLiteLoad || LitaLoad
        },

        setShipmentsTemplates(state, nShipmentsTemplates) {
            nShipmentsTemplates = nShipmentsTemplates.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nShipmentsTemplates.sort(sortByName)
            state.shipmentsTemplates = nShipmentsTemplates
        },

        updateShipmentsTemplates(state, nShipmentsTemplates) {
            if (!state.shipmentsTemplates.length) {
                nShipmentsTemplates = nShipmentsTemplates.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nShipmentsTemplates.sort(sortByName)
                state.shipmentsTemplates = nShipmentsTemplates
                // const chunks = arraySplitIntoChunks(nShipmentsTemplates)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.shipmentsTemplates.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nShipmentsTemplates.forEach(function (nShipmentsTemplate) {
                if (nShipmentsTemplate?.name) nShipmentsTemplate.name_ = nShipmentsTemplate.name.toLocaleLowerCase()
                let i = state.shipmentsTemplates.findIndex(u => (u.id == nShipmentsTemplate.id))
                if (i < 0) {
                    state.shipmentsTemplates.push(nShipmentsTemplate) //(Object.freeze(nShipmentsTemplate))
                } else
                if (!state.shipmentsTemplatesFullLoad || state.shipmentsTemplates[i][changedField] != nShipmentsTemplate[changedField]) {
                    updateObjectByDiff(state.shipmentsTemplates[i], nShipmentsTemplate)
                    // delete nShipmentsTemplate.shipmentsTemplateId
                    // nShipmentsTemplate = {...state.shipmentsTemplates[i], ...nShipmentsTemplate}
                    // state.shipmentsTemplates[i] = nShipmentsTemplate //Object.freeze(nShipmentsTemplate)
                }
            })

        },
        filterShipmentsTemplates(state, nShipmentsTemplates) {
            // let Ids = state.shipmentsTemplates.map(u=> u.shipmentsTemplateId)
            let nIds = nShipmentsTemplates.map(u => u.id)
            let removedIds = state.shipmentsTemplates.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.shipmentsTemplates.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.shipmentsTemplates.splice(i, 1)
                }
            })
        },
        updateShipmentsTemplate(state, nShipmentsTemplate) {
            if (nShipmentsTemplate?.name) nShipmentsTemplate.name_ = nShipmentsTemplate.name.toLocaleLowerCase()
            let i = state.shipmentsTemplates.findIndex(u => (u.id == nShipmentsTemplate.id))
            if (i < 0) {
                state.shipmentsTemplates.push(nShipmentsTemplate) //(Object.freeze(nShipmentsTemplate))
            } else
            //if (!state.shipmentsTemplatesFullLoad || state.shipmentsTemplates[i][changedField] != nShipmentsTemplate[changedField])
            {
                updateObjectByDiff(state.shipmentsTemplates[i], nShipmentsTemplate)
                // delete nShipmentsTemplate.shipmentsTemplateId
                // nShipmentsTemplate = {...state.shipmentsTemplates[i], ...nShipmentsTemplate}
                // state.shipmentsTemplates[i] = nShipmentsTemplate //Object.freeze(nShipmentsTemplate)
            }
        },
        deleteShipmentsTemplate(state, shipmentsTemplateId) {
            let i = state.shipmentsTemplates.findIndex(u => (u.id == shipmentsTemplateId))
            if (i != -1) {
                state.shipmentsTemplates.splice(i, 1)
            }
        },

        clearShipmentsTemplates(state) {
            state.shipmentsTemplates = []
            state.shipmentsTemplatesFullLoad = false
        },
    },
    getters: {
        isShipmentsTemplatesFullLoad(state) {
            return state.shipmentsTemplatesFullLoad
        },
        isShipmentsTemplatesLiteLoad(state) {
            return state.shipmentsTemplatesLiteLoad
        },
        getShipmentsTemplates(state) {
            return state.shipmentsTemplates
        },
        getShipmentNotificationEmails(state, getters) {
            return getters.usersObjectsTemplates
                .filter(t => t.type == 'shipment_notification_email')
                .map(t => {
                    return {
                        id: t.id,
                        name: t.name,
                        type: t.type,
                        emails: t?.fields?.emails || [],
                    }
                })
        }
    }
}
