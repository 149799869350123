const RESOURCE_NAME = '/shipments';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    completeShipment(id,data, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/complete`, data, {params});
    },
    reports(id, type, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/report/${type}`, {}, {params});
    }
});