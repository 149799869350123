<template>
    <section class="car-info-control" v-bind="$attrs" ref="sensors">
        <div class="car-info-dashboard">
            <ul class="car-info-dashboard__list"><!--:class="{'car-info-dashboard__list-60': unitInfo.length <= 6}"-->
                <template v-for="(sensor) in sensors" :key="sensor">
                    <component
                        :class="'car-info-sensor-'+sensor"
                        :is="sensorsComponents.includes('UnitInfo__sensors_'+sensor) ? 'UnitInfo__sensors_'+sensor : 'UnitInfo__sensors_default'"
                        :unitId="unitId"
                        :sensor="sensor"
                        :showByValue="hideSensorByValue"
                    />
                </template>
            </ul>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

import UnitInfo__sensors_default from "@/components/units/sensors/UnitInfo__sensors_default";
import UnitInfo__sensors_name from '@/components/units/sensors/UnitInfo__sensors_name.vue'
import UnitInfo__sensors_imei from '@/components/units/sensors/UnitInfo__sensors_imei.vue'
import UnitInfo__sensors_type from '@/components/units/sensors/UnitInfo__sensors_type.vue'
import UnitInfo__sensors_battery from '@/components/units/sensors/UnitInfo__sensors_battery.vue'
import UnitInfo__sensors_battery_voltage from '@/components/units/sensors/UnitInfo__sensors_battery_voltage.vue'
import UnitInfo__sensors_phaseAB from '@/components/units/sensors/UnitInfo__sensors_phaseAB.vue'
import UnitInfo__sensors_phaseBC from '@/components/units/sensors/UnitInfo__sensors_phaseBC.vue'
import UnitInfo__sensors_phaseCA from '@/components/units/sensors/UnitInfo__sensors_phaseCA.vue'
import UnitInfo__sensors_fuel_level from '@/components/units/sensors/UnitInfo__sensors_fuel_level.vue'
import UnitInfo__sensors_address from '@/components/units/sensors/UnitInfo__sensors_address.vue'
import UnitInfo__sensors_connection_type from '@/components/units/sensors/UnitInfo__sensors_connection_type.vue'
import UnitInfo__sensors_ignition from '@/components/units/sensors/UnitInfo__sensors_ignition.vue'
import UnitInfo__sensors_last_message from '@/components/units/sensors/UnitInfo__sensors_last_message.vue'
import UnitInfo__sensors_status from '@/components/units/sensors/UnitInfo__sensors_status.vue'
import UnitInfo__sensors_mac from '@/components/units/sensors/UnitInfo__sensors_mac.vue'
import UnitInfo__sensors_main_power from '@/components/units/sensors/UnitInfo__sensors_main_power.vue'
import UnitInfo__sensors_humidity from '@/components/units/sensors/UnitInfo__sensors_humidity.vue'
import UnitInfo__sensors_speed from '@/components/units/sensors/UnitInfo__sensors_speed.vue'
import UnitInfo__sensors_temperature from '@/components/units/sensors/UnitInfo__sensors_temperature.vue'
import UnitInfo__sensors_inputs from '@/components/units/sensors/UnitInfo__sensors_inputs.vue'
import UnitInfo__sensors_outputs from '@/components/units/sensors/UnitInfo__sensors_outputs.vue'
import UnitInfo__sensors_brand from '@/components/units/sensors/UnitInfo__sensors_brand.vue'
import UnitInfo__sensors_year from '@/components/units/sensors/UnitInfo__sensors_year.vue'
import UnitInfo__sensors_satelletes from '@/components/units/sensors/UnitInfo__sensors_satelletes.vue'
import UnitInfo__sensors_empty from '@/components/units/sensors/UnitInfo__sensors_empty.vue'
export default {
    name: "UnitInfo_Sensors",
    inject: ['blocksSize'],
    emits: ['update:ready'],
    components:{
        UnitInfo__sensors_default,
        UnitInfo__sensors_battery,
        UnitInfo__sensors_battery_voltage,
        UnitInfo__sensors_phaseAB,
        UnitInfo__sensors_phaseBC,
        UnitInfo__sensors_phaseCA,
        UnitInfo__sensors_fuel_level,
        UnitInfo__sensors_address,
        UnitInfo__sensors_name,
        UnitInfo__sensors_imei,
        UnitInfo__sensors_type,
        UnitInfo__sensors_connection_type,
        UnitInfo__sensors_ignition,
        UnitInfo__sensors_last_message,
        UnitInfo__sensors_status,
        UnitInfo__sensors_mac,
        UnitInfo__sensors_main_power,
        UnitInfo__sensors_humidity,
        UnitInfo__sensors_speed,
        UnitInfo__sensors_temperature,
        UnitInfo__sensors_inputs,
        UnitInfo__sensors_outputs,
        UnitInfo__sensors_brand,
        UnitInfo__sensors_year,
        UnitInfo__sensors_satelletes,
        UnitInfo__sensors_empty,
    },
    props:{
        unitId: [String, Number],
        ready: Boolean,
        hideSensorByValue:{
            type: Boolean,
            default: false
        },
        sensors: {
            type: [Array],
            default: () => [
                'last_message',
                'speed',
                'status',
                'ignition',
                'main_power',
                'battery',
                'inputs',
                'outputs',
            ]
        }
    },
    data(){
        return{
        }
    },
    computed:{
        ...mapGetters([
            'unitsByIds'
        ]),
        sensorsComponents() {
            return Object.keys(this._.components)
        }
    },
    methods:{
    },
    updated() {
        // console.log('updated UnitInfo_Sensors')
    },
    mounted() {
        // console.log('mounted UnitInfo_Sensors')
        setTimeout(() => this.$emit('update:ready', true), 300)
    }
}
</script>

<style scoped lang="scss">
.unit-info-prometheus {
    &__sensors-accordion {
        .car-info-dashboard{
            &__list {
                grid-template-columns: 1fr;
            }
        }
    }
}

</style>