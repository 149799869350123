// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        usersObjectsTemplatesFullLoad: false,
        usersObjectsTemplatesLiteLoad: false,
        usersObjectsTemplates: [],
    },
    actions: {
        fetchUsersObjectsTemplates/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUsersObjectsTemplates', time: Date.now() / 1000})

                const params = getApiProps('usersObjectsTemplates', args)
                this.$api.usersObjectsTemplates.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUsersObjectsTemplates', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setUsersObjectsTemplatesLiteLoad', true)
                        commit('setUsersObjectsTemplatesFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchUsersObjectsTemplates', inprogress: false})
                    });
            })
        },
        fetchUsersObjectsTemplatesByType/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args?.type) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUsersObjectsTemplatesByType', time: Date.now() / 1000})

                const params = getApiProps('usersObjectsTemplates', args)
                this.$api.usersObjectsTemplates.templatesByType(args?.type, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUsersObjectsTemplates', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUsersObjectsTemplatesByType', inprogress: false})
                    });
            })
        },

        saveUsersObjectsTemplate({dispatch}, usersObjectTemplate) {
            let fn = (usersObjectTemplate.id) ? 'updateUsersObjectsTemplate' : 'createUsersObjectsTemplate'
            return dispatch(fn, usersObjectTemplate);
        },
        createUsersObjectsTemplate({commit, getters}, usersObjectTemplate) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !usersObjectTemplate?.name || !usersObjectTemplate?.type || !usersObjectTemplate?.fields) {
                    return reject(false);
                }
                const params = getApiProps('usersObjectsTemplates')
                this.$api.usersObjectsTemplates.create(usersObjectTemplate, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUsersObjectsTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateUsersObjectsTemplate({commit, getters}, usersObjectTemplate) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !usersObjectTemplate?.id || !usersObjectTemplate?.type) {
                    return reject(false);
                }
                const params = getApiProps('usersObjectsTemplates')
                this.$api.usersObjectsTemplates.update(usersObjectTemplate.id, usersObjectTemplate, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUsersObjectsTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteUsersObjectsTemplate({commit}, usersObjectTemplateId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('usersObjectsTemplates')
                this.$api.usersObjectsTemplates.delete(usersObjectTemplateId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (!response.data || !response.data.error) {
                            if (!response.data) commit('deleteUsersObjectsTemplate', usersObjectTemplateId)
                            else commit('updateUsersObjectsTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setUsersObjectsTemplatesFullLoad(state, FullLoad) {
            state.usersObjectsTemplatesFullLoad = state.usersObjectsTemplatesFullLoad || FullLoad
        },
        setUsersObjectsTemplatesLiteLoad(state, LitaLoad) {
            state.usersObjectsTemplatesLiteLoad = state.usersObjectsTemplatesLiteLoad || LitaLoad
        },

        setUsersObjectsTemplates(state, nUsersObjectsTemplates) {
            nUsersObjectsTemplates = nUsersObjectsTemplates.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nUsersObjectsTemplates.sort(sortByName)
            state.usersObjectsTemplates = nUsersObjectsTemplates
        },

        updateUsersObjectsTemplates(state, nUsersObjectsTemplates) {
            if (!state.usersObjectsTemplates.length) {
                nUsersObjectsTemplates = nUsersObjectsTemplates.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nUsersObjectsTemplates.sort(sortByName)
                state.usersObjectsTemplates = nUsersObjectsTemplates
                // const chunks = arraySplitIntoChunks(nUsersObjectsTemplates)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.usersObjectsTemplates.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nUsersObjectsTemplates.forEach(function (nUsersObjectTemplate) {
                if (nUsersObjectTemplate?.name) nUsersObjectTemplate.name_ = nUsersObjectTemplate.name.toLocaleLowerCase()
                let i = state.usersObjectsTemplates.findIndex(u => (u.id == nUsersObjectTemplate.id))
                if (i < 0) {
                    state.usersObjectsTemplates.push(nUsersObjectTemplate) //(Object.freeze(nUsersObjectTemplate))
                } else
                if (!state.usersObjectsTemplatesFullLoad || state.usersObjectsTemplates[i][changedField] != nUsersObjectTemplate[changedField]) {
                    updateObjectByDiff(state.usersObjectsTemplates[i], nUsersObjectTemplate)
                    // delete nUsersObjectTemplate.usersObjectTemplateId
                    // nUsersObjectTemplate = {...state.usersObjectsTemplates[i], ...nUsersObjectTemplate}
                    // state.usersObjectsTemplates[i] = nUsersObjectTemplate //Object.freeze(nUsersObjectTemplate)
                }
            })

        },
        filterUsersObjectsTemplates(state, nUsersObjectsTemplates) {
            // let Ids = state.usersObjectsTemplates.map(u=> u.usersObjectTemplateId)
            let nIds = nUsersObjectsTemplates.map(u => u.id)
            let removedIds = state.usersObjectsTemplates.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.usersObjectsTemplates.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.usersObjectsTemplates.splice(i, 1)
                }
            })
        },
        updateUsersObjectsTemplate(state, nUsersObjectTemplate) {
            if (nUsersObjectTemplate?.name) nUsersObjectTemplate.name_ = nUsersObjectTemplate.name.toLocaleLowerCase()
            let i = state.usersObjectsTemplates.findIndex(u => (u.id == nUsersObjectTemplate.id))
            if (i < 0) {
                state.usersObjectsTemplates.push(nUsersObjectTemplate) //(Object.freeze(nUsersObjectTemplate))
            } else
            //if (!state.usersObjectsTemplatesFullLoad || state.usersObjectsTemplates[i][changedField] != nUsersObjectTemplate[changedField])
            {
                updateObjectByDiff(state.usersObjectsTemplates[i], nUsersObjectTemplate)
                // delete nUsersObjectTemplate.usersObjectTemplateId
                // nUsersObjectTemplate = {...state.usersObjectsTemplates[i], ...nUsersObjectTemplate}
                // state.usersObjectsTemplates[i] = nUsersObjectTemplate //Object.freeze(nUsersObjectTemplate)
            }
        },
        deleteUsersObjectsTemplate(state, usersObjectTemplateId) {
            let i = state.usersObjectsTemplates.findIndex(u => (u.id == usersObjectTemplateId))
            if (i != -1) {
                state.usersObjectsTemplates.splice(i, 1)
            }
        },

        clearUsersObjectsTemplates(state) {
            state.usersObjectsTemplates = []
            state.usersObjectsTemplatesFullLoad = false
        },
    },
    getters: {
        isUsersObjectsTemplatesFullLoad(state) {
            return state.usersObjectsTemplatesFullLoad
        },
        isUsersObjectsTemplatesLiteLoad(state) {
            return state.usersObjectsTemplatesLiteLoad
        },
        usersObjectsTemplates(state) {
            return state.usersObjectsTemplates
        },
        usersObjectsTemplatesByIds(state) {
            return state.usersObjectsTemplates
                .reduce((usersObjectsTemplatesByIds, usersObjectTemplate) => {
                usersObjectsTemplatesByIds[usersObjectTemplate.id] = usersObjectTemplate
                return usersObjectsTemplatesByIds
            }, {})
        },
        sortedUsersObjectsTemplatesIds(state) {
            let usersObjectsTemplates = state.usersObjectsTemplates
            usersObjectsTemplates.sort(sortByName)
            return usersObjectsTemplates.map(u => u.id)
        },
    }
}
